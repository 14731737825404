// common modules
import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress } from "@react-md/progress";
import { Button } from 'react-md';
import { useParams, useHistory } from "react-router-dom";
import _ from 'lodash';

import usePageFramework from '@utilities/hooks/usePageFramework';
import { getEmbeddedSigningUrl } from '@utilities/services/taxReturnService';
import { DASHBOARD, DOCUSIGN_EMBEDDED_SIGNING } from '@utilities/constants/navigation';
import { featureFlagTypes } from '@utilities/constants/featureFlags';

// custom modules
import './style.css';

function DocusignEmbeddedSigning(props) {
    const {
        REDUX,
        selectState,
    } = usePageFramework(DOCUSIGN_EMBEDDED_SIGNING);
    
    const featureFlags = selectState(REDUX.FEATURE_FLAGS);
    const embeddingUrlFeatureFlag = featureFlags
        ? JSON.parse(JSON.stringify(featureFlags))
            .find(flag => flag.key === featureFlagTypes.EmbeddedSigningUrl)?.value
        : false;
    const isEmbeddingUrlFeatureEnabled = embeddingUrlFeatureFlag === true
        || embeddingUrlFeatureFlag === "true";

    const history = useHistory();
    const iframeRef = useRef(null);

    const organizerId = selectState(REDUX.ORGANIZER_ID);
    const [embeddedUrl, setEmbeddedUrl] = useState(null);
    const [errorText, setErrorText] = useState(null);

    const redirectToDashboard = () => {
        history.push(DASHBOARD);
    }

    // TODO: in case we need to check the iframe location for auto redirect.    
    // useEffect(() => {
    //     const checkIframeLocationInterval = setInterval(() => {
    //         const iframe = iframeRef.current;
    //         if (iframe) {
    //             try {
    //                 if (iframe.contentWindow.location.origin === window.location.origin) {
    //                     redirectToDashboard();
    //                 }
    //             } catch (error) {
    //                 console.log('expected security error, cross-origin when accessing the origin.', error);
    //             }
    //         }
    //     }, 1000); // Check every 1 seconds
    //     return () => clearInterval(checkIframeLocationInterval); // Cleanup interval on component unmount
    // }, []);

    // on load, check if user has envelopeId, if so, we get the embeded signing url.
    // else show text "Tax return is not ready for signing".

    useEffect(() => {

        if (!organizerId || !isEmbeddingUrlFeatureEnabled) {
            redirectToDashboard();
        }

        if (!embeddedUrl && !errorText) {
            getEmbeddedSigningUrl(organizerId)
                .then(response => {
                    if (response) {
                        const result = response.data;
                        if (result.isSuccess) {
                            setEmbeddedUrl(result.data);
                        } else {
                            console.error(`expected error: `, result);
                            setErrorText(result.data ?? `Error getting embedded signing URL.`);
                        }
                    } else {
                        console.error(`unhandled error: `, response);
                        setErrorText(`Error getting embedded signing URL.`);
                    }
                })
                .catch(error => setErrorText(error.message));
        }
    }, [embeddedUrl, errorText, organizerId]);

    if (!embeddedUrl && !errorText) return <CircularProgress id="loading-progress" />;

    return (
        <div className={`esignature-container ${errorText ? 'error-text' : ''}`}>
            {errorText}
            {embeddedUrl && <iframe
                ref={iframeRef}
                className={`docusign-iframe`}
                title="Embedded Signing"
                src={embeddedUrl}
            />}
            <div>
                <Button
                    themeType="contained"
                    className={'entryNextButton uploadButton return-to-dashboard-btn'}
                    onClick={redirectToDashboard}
                >
                    Return to Dashboard
                </Button>
            </div>
        </div>
    );
}

export default DocusignEmbeddedSigning;