// common modules


// custom modules
import '@components/dialog/customDialog/customDialog.css';
import * as IMG from '@utilities/constants/images.js';


function ErrorDialog(
  dispatch,
  ACTION,
  title,
  message,
  isTryAgainVisible = false,
  tryAgainCallback = null,
  nextLineTitle = null,
) {
    dispatch(ACTION.setCustomDialogTitle(
      <div className='exportErrorContainer'>
        <div>
          <img className="warningDialogIcon"
            width="24"
            height="20"
            alt="Warning icon"
            src={IMG.WARNING_ICON}
          />
          <span className="errorHeaderText">{title}</span>
        </div>
        { nextLineTitle && <span className="errorHeaderText">{nextLineTitle}</span> }
      </div>
    ));			
    dispatch(ACTION.setCustomDialogMsg(
        <>
        <div className={`exportErrorMsg${isTryAgainVisible ? ' colorDarkGray' : ''}`}>{message}</div>
      </>
    ));
    dispatch(ACTION.setShowCustomDialog(true));
    dispatch(ACTION.setCustomDialogTryAgain(isTryAgainVisible));
    dispatch(ACTION.setCustomDialogTryAgainCallback(tryAgainCallback));
}
export default ErrorDialog;