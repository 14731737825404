import {
	Button,
	Dialog,
	useToggle,
	FontIcon
} from 'react-md';
import { frameworkSetter } from '../../../hooks/usePageFramework';
import { useState } from 'react';

function BlobFileViewer(props) {
	const { onFilePreview, downloadFile, buildDocumentPreview, renderTiffImage, dispatch, ACTION, selectState, REDUX } = frameworkSetter.usePageFramework();
	const { blobFile, className, parentElement, fileName, from, setIsShow, isShow } = props;
	const [visible, show, hide] = useToggle(false);
	const [previewVisible, showPreview, hidePreview] = useToggle(false);
	const [source, setSource] = useState('');
	const documentDownloading = selectState(REDUX.DOCUMENT_DOWNLOADING);

	const handlePreview = () => {
		if (!source) {
			// Load the file for first time open
			if ((blobFile.extension.includes('tiff') || blobFile.extension.includes('tif'))) {
				onFilePreview(blobFile, { responseType: 'arraybuffer' })?.then((arrayBuffer) => {
					let imageBase64 = renderTiffImage(arrayBuffer, blobFile);
					setSource(imageBase64);
					show();
					showPreview();
				});
			} else {
				onFilePreview(blobFile)?.then((source) => {
					setSource(source);
					show();
					showPreview();
				});
			}

		} else {
			show();
			showPreview();
		}
	};

	const handleDownload = (evt) => {
		evt.preventDefault();
		return downloadFile(blobFile);
	};

	if (from === 'docManager') {
		return (
			<Dialog
				id={`dialogViewer-${blobFile.upload_id}`}
				visible={isShow}
				onRequestClose={() => { }}
				modal={true}
				aria-labelledby="blob-file-viewer"
				className='viewerContainer'
			>
				<div >
					<Button
						className='fileViewerCLose'
						id="dialog-close"
						onClick={() => setIsShow(!isShow)}
						first="true"
						last="true"
						buttonType='icon'
					>
						<FontIcon >close</FontIcon>
					</Button>
				</div>
				<h3 className='fileViewerFileName'>{blobFile.name}</h3>
				<a
					className='fileViewerDownload'
					href={'#'}
					target="_blank"
					rel="noreferrer"
					onClick={documentDownloading ? undefined : handleDownload}
					style={{
						pointerEvents: documentDownloading ? 'none' : 'auto',
						opacity: documentDownloading ? 0.5 : 1,
						color: documentDownloading ? 'gray' : '#123A65',
					}}>Download</a>
				{
					buildDocumentPreview
						? buildDocumentPreview(source, blobFile)
						: previewVisible
							? <iframe title={`${blobFile.name} Preview`} width="100%" height="100%" src={source}></iframe>
							: <div className='fileViewerNotAvail'> (No preview available) </div>
				}
				<div className='uploadedByInfo'>Uploaded by: {blobFile.uploaded_by}</div>
			</Dialog>
		);
	}

	return (
		<>
			{parentElement ? parentElement :
				<div data-testid='showFileLink' className={className} onClick={handlePreview} first="true" last="true">{fileName}</div>}
			<Dialog
				id={`dialogViewer-${blobFile.upload_id}`}
				visible={visible}
				onRequestClose={() => { }}
				modal={true}
				aria-labelledby="blob-file-viewer"
				className='viewerContainer'
			>
				<div >
					<Button className='fileViewerCLose' id="dialog-close" onClick={hide} first="true" last="true" buttonType='icon'>
						<FontIcon >close</FontIcon>
					</Button>
				</div>
				<h3 className='fileViewerFileName'>{blobFile.name}</h3>
				<a className='fileViewerDownload'
					href={'#'}
					target="_blank"
					rel="noreferrer"
					onClick={documentDownloading ? undefined : handleDownload}
					style={{
						pointerEvents: documentDownloading ? 'none' : 'auto',
						opacity: documentDownloading ? 0.5 : 1,
						color: documentDownloading ? 'gray' : '#123A65',
					}}>Download</a>
				{
					buildDocumentPreview
						? buildDocumentPreview(source, blobFile)
						: previewVisible
							? <iframe title={`${blobFile.name} Preview`} width="100%" height="100%" src={source}></iframe>
							: <div className='fileViewerNotAvail'> (No preview available) </div>
				}
			</Dialog>
		</>
	);
}

export default BlobFileViewer;
