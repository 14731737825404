// common modules
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useToggle } from "@react-md/utils";
// MUI imports
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

// Custom components
import CustomNoResultsOverlay from './customNoResultsOverlay';
import Breadcrumb from '@components/Breadcrumb.js';
import CreateResourceDialog from '@components/restfulTable/CreateResourceDialog.js';
import DeleteResourceDialog from '@components/restfulTable/DeleteResourceDialog.js';
import EditResourceDialog from '@components/restfulTable/EditResourceDialog.js';

// Utilities and hooks
import usePageFramework from '@utilities/hooks/usePageFramework';
import { MAX_PREFETCH_LIMIT, DEFAULT_FILTER_QUERY, DEFAULT_SORT_QUERY, OPERATORS_MAP, DEFAULT_PAGE_LIMIT, SORT_TO_DATA_MAP, FILTER_TO_DATA_MAP } from '@utilities/constants/manageNotification.js';
import useServerSideMUI from '@utilities/hooks/useServerSideMUI';
import api from '@utilities/claApi';

// Custom components and hooks for this module
import FilterButton from './components/FilterButton';
import OptionDropdownMenu from './components/OptionDropdownMenu';
import useNotificationApi from './hooks/useNotificationApi';

// Constants and configurations
import {
  RESOURCE_NAME,
  RESOURCE_URI,
  NAV_ITEMS,
  getColumnsGrid,
  getDialogColumns,
  buildOrganizerQuery
} from './constants';

// CSS
import './manageNotification.css';

// Main component, now more focused and clean
function ManageNotifications() {
  const {
    ACTION,
    dispatch,
  } = usePageFramework();

  // State management
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [isLoaded, enable, disable] = useToggle(false);
  const [paginatedLoad, setPaginatedLoad] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [showCreateDialog, enableCreateDialog, disableCreateDialog] = useToggle(false);
  const [showDeleteDialog, enableDeleteDialog, disableDeleteDialog] = useToggle(false);
  const [showEditDialog, enableEditDialog, disableEditDialog] = useToggle(false);
  const [selected, setSelected] = useState({});
  const previousOrganizerQuery = useRef(null);
  const [prefetchedData, setPrefetchedData] = useState([]);
  const [data, setData] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  // State for UI indicators
  const [isTileActive, setIsTileActive] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);

  // Custom API hook
  const { createResource, updateResource, deleteResource, exportToCSV } = useNotificationApi(
    RESOURCE_URI,
    dispatch,
    ACTION
  );

  // Server-side MUI data handling
  const payload = {
    defaultFilter: DEFAULT_FILTER_QUERY,
    defaultSort: DEFAULT_SORT_QUERY,
    defaultPageSize: DEFAULT_PAGE_LIMIT,
    filterDataMap: FILTER_TO_DATA_MAP,
    sortDataMap: SORT_TO_DATA_MAP,
    operatorsMap: OPERATORS_MAP,
    defaultPrefetchSize: MAX_PREFETCH_LIMIT,
  };

  const {
    handleFilterModelChange,
    handleSortModelChange,
    handlePageModelChange,
    handlePageSizeChange,
    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
    pageSize,
    prefetchSize,
    currentFilterModel,
    currentSortModel,
  } = useServerSideMUI(payload);

  // Constants and derived values
  const filterHighlight = currentFilterModel?.items?.[0]?.value ? 'filterButtonHighlight' : 'filterButton';
  // Build organizer query for the current state
  const orgQuery = buildOrganizerQuery(
    totalResults,
    0,
    sortQueryParameters,
    filterMethodParameters,
    filterQueryParameters
  );

  // Handle export to CSV
  const handleExportToCSV = (query) => {
    exportToCSV(query);
  };

  // Grid columns configuration with handlers
  const columnsGrid = useMemo(() => getColumnsGrid({
    enableCreateDialog,
    setSelected,
    enableEditDialog,
    enableDeleteDialog,
    setFilterButtonEl,
    filterHighlight,
    FilterButtonComponent: FilterButton,
    OptionDropdownMenuComponent: () => (
      <OptionDropdownMenu
        handleExportToCSV={handleExportToCSV}
        isFilterActive={isFilterActive}
        isTileActive={isTileActive}
        orgQuery={orgQuery}
      />
    )
  }), [
    enableCreateDialog,
    enableEditDialog,
    enableDeleteDialog,
    filterHighlight,
    isFilterActive,
    isTileActive,
    orgQuery
  ]);

  // Process columns for dialog
  const columns = useMemo(() => getDialogColumns(columnsGrid), [columnsGrid]);

  // Row spacing callback
  const getRowSpacing = useCallback((params) => ({
    top: params.isFirstVisible ? 30 : 6,
    bottom: params.isLastVisible ? 9 : 6,
  }), []);

  // Set filter active state when filter model changes
  useEffect(() => {
    setIsFilterActive(!!currentFilterModel?.items?.[0]?.value);
  }, [currentFilterModel]);

  // Data fetching effect
  useEffect(() => {
    if (!isLoaded || forceRefresh) {
      fetchNotifications();
      if (forceRefresh) {
        setForceRefresh(false);
      }
    }
  }, [isLoaded, sortQueryParameters, filterMethodParameters, filterQueryParameters, pageSize, currentPageNumber, forceRefresh]);

  // Fetch notifications with proper pagination and caching
  const fetchNotifications = async () => {
    setPaginatedLoad(true);
    const totalOffset = currentPageNumber * pageSize;
    const prefetchOffset = Math.floor(totalOffset / prefetchSize) * prefetchSize;
    const prefetchPageOffset = totalOffset % prefetchSize;

    const organizerQuery = buildOrganizerQuery(
      prefetchSize,
      prefetchOffset,
      sortQueryParameters,
      filterMethodParameters,
      filterQueryParameters
    );

    if (!forceRefresh && previousOrganizerQuery.current !== null && organizerQuery === previousOrganizerQuery.current) {
      const resultSlice = prefetchedData.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
      setData(resultSlice);
      setPaginatedLoad(false);
      enable();
      setIsTileActive(resultSlice.length > 0);
      return;
    }

    try {
      const response = await api.get(`${RESOURCE_URI}?${organizerQuery}`);
      const { results, total } = response.data;

      previousOrganizerQuery.current = organizerQuery;
      const resultSlice = results.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
      setPrefetchedData(results);
      setData(resultSlice);
      setTotalResults(total);
      setIsTileActive(results.length > 0);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      setIsTileActive(false);
    } finally {
      enable();
      setPaginatedLoad(false);
    }
  };

  const refreshData = () => {
    disable();
    setForceRefresh(true);
  };

  // Handle create notification
  const handleCreateResource = async (resource) => {
    const success = await createResource(resource);
    if (success) {
      disableCreateDialog();
      refreshData();
    }
  };

  // Handle update notification
  const handleUpdateResource = async (id, update) => {
    const success = await updateResource(id, update);
    if (success) {
      disableEditDialog();
      refreshData();
    }
  };

  // Handle delete notification
  const handleDeleteResource = async (id) => {
    const success = await deleteResource(id);
    if (success) {
      disableDeleteDialog();
      refreshData();
    }
  };

  return (
    <div className="pracDashboardSizeNotifications">
      <Breadcrumb items={NAV_ITEMS} />
      <Box className="tableContainer" data-testid="pracdash-columnheader">
        <DataGridPro
          className="notification-data-grid"
          loading={paginatedLoad}
          filterMode="server"
          disableMultipleColumnsFiltering={false}
          filterModel={currentFilterModel}
          onFilterModelChange={(filterModel) => {
            handleFilterModelChange(filterModel);
            disable();
          }}
          filterDebounceMs={300}
          getRowSpacing={getRowSpacing}
          disableVirtualization={true}
          rows={data}
          columns={columnsGrid}
          pageSizeOptions={[25, 50]}
          columnVisibilityModel={{}}
          disableColumnResize={true}
          disableMultipleRowSelection={true}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          paginationMode="server"
          rowCount={totalResults}
          disableColumnSeparator={true}
          onPaginationModelChange={(props) => {
            handlePageSizeChange(props.pageSize);
            handlePageModelChange(props.page);
            disable();
          }}
          sortingMode="server"
          paginationModel={{ pageSize: pageSize, page: currentPageNumber }}
          sortModel={currentSortModel}
          onSortModelChange={(sortModel) => {
            handleSortModelChange(sortModel);
            disable();
          }}
          localeText={{ toolbarFilters: "" }}
          autoHeight={false}
          rowHeight={80}
          pagination
          slots={{
            noRowsOverlay: CustomNoResultsOverlay,
            noResultsOverlay: CustomNoResultsOverlay
          }}
          slotProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
            toolbar: {
              setFilterButtonEl,
              filterHighlight
            },
          }}
        />
      </Box>

      {/* Dialog components */}
      <CreateResourceDialog
        visible={showCreateDialog}
        resourceName={RESOURCE_NAME}
        resourceProps={columns}
        onCancel={disableCreateDialog}
        onConfirm={handleCreateResource}
      />

      <DeleteResourceDialog
        visible={showDeleteDialog}
        resourceName={RESOURCE_NAME}
        resourceProps={columns}
        onCancel={disableDeleteDialog}
        onConfirm={() => handleDeleteResource(selected.id)}
      />

      <EditResourceDialog
        visible={showEditDialog}
        selected={selected}
        resourceName={RESOURCE_NAME}
        resourceProps={columns}
        onCancel={disableEditDialog}
        onConfirm={handleUpdateResource}
      />
    </div>
  );
}

export default ManageNotifications;