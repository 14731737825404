import api from '@utilities/claApi';
import moment from 'moment/moment';

const useNotificationApi = (resourceUri, dispatch, ACTION) => {
    // Create a new notification
    const createResource = async (resource) => {
        dispatch(ACTION.setProgressText('Notification Creating...'));
        dispatch(ACTION.setProgressVisible(true));

        if (!resource.active) {
            resource.active = false;
        }

        // Convert startDate/endDate to contain timezeones
        resource.startDate = moment(resource.startDate).toDate();
        resource.endDate = moment(resource.endDate).toDate();

        try {
            await api.post(resourceUri, resource);
            dispatch(ACTION.setProgressVisible(false));
            return true;
        } catch (error) {
            console.error(error);
            //TODO: Next step (not this time) will be that we need to consolidate these actions. Shouldn't take 3 separate actions to open a modal.
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setCustomDialogTitle('An error has occurred when creating the scheduled notification'));
            dispatch(ACTION.setShowCustomDialog(true));
            return false;
        }
    };

    // Update an existing notification
    const updateResource = async (id, update) => {
        dispatch(ACTION.setProgressText('Notification Updating...'));
        dispatch(ACTION.setProgressVisible(true));
        delete update?.id;

        // Convert startDate/endDate to contain timezeones
        update.startDate = moment(update?.startDate).toDate();
        update.endDate = moment(update?.endDate).toDate();

        try {
            await api.put(`${resourceUri}/${id}`, update);
            dispatch(ACTION.setProgressVisible(false));
            return true;
        } catch (error) {
            console.error(error);
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setCustomDialogTitle('An error has occurred when editing the scheduled notification'));
            dispatch(ACTION.setShowCustomDialog(true));
            return false;
        }
    };

    // Delete a notification
    const deleteResource = async (id) => {
        dispatch(ACTION.setProgressText('Notification Deleting...'));
        dispatch(ACTION.setProgressVisible(true));

        try {
            await api.delete(`${resourceUri}/${id}`);
            dispatch(ACTION.setProgressVisible(false));
            return true;
        } catch (error) {
            console.error(error);
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setCustomDialogTitle('An error has occurred when deleting the scheduled notification'));
            dispatch(ACTION.setShowCustomDialog(true));
            return false;
        }
    };

    // Export notifications to CSV
    const exportToCSV = async (orgQuery) => {
        dispatch(ACTION.setProgressText('Exporting to CSV...'));
        dispatch(ACTION.setProgressVisible(true));

        try {
            await api.get(`${resourceUri}/export?${orgQuery}`);
            dispatch(ACTION.setProgressVisible(false));
            return true;
        } catch (error) {
            console.error(error);
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setCustomDialogTitle('An error has occurred when exporting to CSV'));
            dispatch(ACTION.setShowCustomDialog(true));
            return false;
        }
    };

    return {
        createResource,
        updateResource,
        deleteResource,
        exportToCSV
    };
};

export default useNotificationApi; 