import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import EntityScrollTab from './entityScrollTab';
import { findEntityNameFields, getFieldObject } from '@utilities/axcessTaxPull';
import { findMultiFieldsFromPYData } from '@utilities/populatePriorData/searchPullValues';
import loadPriorEntityData from '@utilities/populatePriorData/parsePriorEntityData';
import { handleSharedEntityDialog } from './sharedEntity';
import { v4 as uuidv4 } from 'uuid';
import useAdaptive from '@utilities/hooks/useAdaptive';
import getYear from '@utilities/helpers/getYear';

function EntityRenderer(props) {
    const { sections, formName, identifiers, isLineSection, lineItemCount, matchIdentifiers, spreadIdentifiers, groupByEntityId = true, isFormSection, iscomingFromVehicleEntity, parentFormName, entityFilter, isFormPresent, isFormExpandable,
        triggeredEvent, sectionFieldLogic, isParentEntityNA, groupedEntityIdentifiers, isExportForm, barcodeName } = props;
    const { selectState, setEntityFormState, REDUX, dispatch, ACTION, updateStatusCard } = usePageFramework(formName);
    const formState = selectState(formName); //for vehicle - all the entities in the list
    const priorYearData = !identifiers ? null : selectState(REDUX.PRIOR_YEAR_DATA);
    const isFormLocked = selectState(REDUX.LOCK_FORMS);
    const isFormNA = selectState(REDUX.IS_CURRENT_FORM_NA);
    const isFormDisabled = isFormLocked ? isFormLocked : isFormNA;
    const [entitiesList, setEntitiesList] = useState([]); //for vehicle - filtered vehicle list by parent entity reference
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [isScheduleFormSection, setIsScheduleFormSection] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [entityNameFields, setEntityNameFields] = useState(null);
    const entityCount = lineItemCount || 0;
    const [entityBuilderId, setEntityBuilderId] = useState(uuidv4());
    const key = `${formName}-${entityBuilderId}-${currentIndex}-${entityCount}`;
    const formNameKey = parentFormName && entitiesList?.length ? entitiesList[currentIndex]?.formNameKey : `${formName}-${currentIndex}-${entityCount}`;
    const filteredSections = sections.filter(section => !section.isSubSection);
    const year = getYear(selectState)

    const { isMobile } = useAdaptive();

    const changeSection = (index, section) => {
        if (section) {
            setIsScheduleFormSection(section.title);
            dispatch(ACTION.setExpandedSubFormState(formNameKey, section.title === 'Vehicle Expenses'));
        }
        setCurrentSectionIndex(index);
    };

    const updateState = (entities) => {
        if (!isExportForm) setEntityFormState(formName, entities);
    };

    const changeEntityIndex = (index) => {
        setCurrentIndex(index);
    };

    const filterEntitiesList = (fullEntitiesList) => {
        return parentFormName ? fullEntitiesList?.filter((entity) => {
            return entity?.parentEntityKeys?.some((parentEntityKey) => {
                return parentEntityKey.parentFormName === parentFormName && !parentEntityKey.isRemoved;
            });
        }) : fullEntitiesList;
    };

    useEffect(() => {
        if (isLoaded || !entityNameFields) return;
        if (isExportForm && !formState) return;
        let initialEntitiesList = formState && formState.length && !formState[0].section ? [] : formState || [];
        let initalizedEntities = false;
        const rawSections = _.cloneDeep(sections);

        if (identifiers && priorYearData) {
            const priorYearEntities = loadPriorEntityData(identifiers, matchIdentifiers, spreadIdentifiers, priorYearData, entityFilter, { groupByEntityId }, groupedEntityIdentifiers);
            if (initialEntitiesList.length === 0) {
                priorYearEntities.forEach((priorYearEntity, index) => {
                    const entitiyNames = entityNameFields
                        .map((x) => getFieldObject(rawSections, x))
                        .map(field => findMultiFieldsFromPYData(priorYearEntity, field)).filter(x => x);
                    const childFormNameKey = `${formName}-${index}-${entityCount}`;
                    const entityObject = {
                        entityIndex: initialEntitiesList.length,
                        name: entitiyNames?.[0] || '',
                        formNameKey: childFormNameKey,
                        name: entitiyNames?.[0] || '',
                        identifier: priorYearEntity,
                        section: rawSections,
                        parentEntityKeys: parentFormName ? [{ parentFormName: parentFormName }] : [],
                    };

                    initalizedEntities = true;
                    initialEntitiesList.push(entityObject);
                });
            }
        }

        if (!filterEntitiesList(initialEntitiesList).length) {
            addEntity();
        } else {
            setEntitiesList(filterEntitiesList(initialEntitiesList));
        }

        if (initalizedEntities && !parentFormName) {
            updateState(initialEntitiesList);
        }

        setIsLoaded(true);
        // eslint-disable-next-line
    }, [entityNameFields, isExportForm ? formState : null]);

    useEffect(() => {
        // Find field and priority which affect the Entity Name
        const entityNameFields = findEntityNameFields(sections);
        setEntityNameFields(entityNameFields);
    }, [sections]);

    const addEntity = () => {
        const copySection = _.cloneDeep(sections);
        const newIdentifiers = !identifiers ? [] : _.cloneDeep(identifiers).map(x => {
            x.isNew = true;
            return x;
        });

        if (Array.isArray(copySection)) {
            copySection.filter(section => section.groups).forEach(section => {
                section.groups.filter(group => group.prePopulate).forEach(group => group.isNew = true);
            });
        }

        const newIndex = parentFormName && formState ? formState?.length : entitiesList?.length;
        const newParentEntityKeys = [{ parentFormName: parentFormName, isNewRelative: true }] || []; // to know what form entity the vehicle is associated
        const childFormNameKey = `${formName}-${newIndex}-${entityCount}`;

        const newEntity = {
            section: copySection,
            entityIndex: newIndex,
            identifier: newIdentifiers,
            parentEntityKeys: newParentEntityKeys,
            formNameKey: childFormNameKey,
            isNewEntity: true,
        };

        const entities = [
            ...(parentFormName && formState ? formState : entitiesList),
            newEntity,
        ];

        changeEntityIndex(entitiesList?.length);
        setEntitiesList(filterEntitiesList(entities));
        updateState(entities);
        setEntityBuilderId(uuidv4());
    };

    const updateEntity = (entityName, formSections) => {
        if (!entityNameFields || !entityNameFields.length) {
            setEntityName(entityName?.trim() || '');
        }

        const entityNames = entityNameFields.reduce((prev, entityNameField) => {
            const entityName = getFieldValue(formSections, entityNameField);
            if (entityName) {
                prev.push(entityName);
            }

            return prev;
        }, []);
        setEntityName(entityNames?.[0] || entityName?.trim() || '');
    };

    const getFieldValue = (currentFormSections, fieldLocation) => {
        return getFieldObject(currentFormSections, fieldLocation)?.default?.trim();
    };

    const setEntityName = (entityName) => {
        const clonedEntitiesList = _.cloneDeep(entitiesList);
        clonedEntitiesList[currentIndex].name = entityName;
        setEntitiesList(clonedEntitiesList);

        const listDeepCopy = _.cloneDeep(formState);
        listDeepCopy.forEach((entity) => {
            if (entity?.formNameKey === clonedEntitiesList[currentIndex]?.formNameKey) {
                entity.name = entityName;
            }
        });

        updateState(parentFormName ? listDeepCopy : clonedEntitiesList);
    };

    const addExistingEntity = (formKey) => {
        const listDeepCopy = _.cloneDeep(formState);
        let unNamedVehicleKey = '';

        // to check if the initial entity vehicle is to be removed
        if (entitiesList.length === 1) {
            const unNamedVehicle = entitiesList.find((vehicle) => typeof (vehicle.name) === 'undefined' && !vehicle.identifier.length);
            unNamedVehicleKey = unNamedVehicle?.formNameKey;
        }

        listDeepCopy.forEach((entity) => {
            if (entity?.formNameKey === formKey) {

                const foundParentEntity = entity.parentEntityKeys.find(x => x.parentFormName === parentFormName);

                if (foundParentEntity) {
                    foundParentEntity.isRemoved = false;
                } else {
                    entity.parentEntityKeys.push({ parentFormName: parentFormName, isNewRelative: true });
                }
            }
            if (entity?.formNameKey === unNamedVehicleKey) {
                const foundParentToRemove = entity.parentEntityKeys.find(x => x.parentFormName === parentFormName);
                foundParentToRemove.isRemoved = true;
            }
        });

        setEntitiesList(filterEntitiesList(listDeepCopy));
        updateState(listDeepCopy);
        setEntityBuilderId(uuidv4());
    };

    const removeEntityFromList = (formKey) => {
        let removeIndex = 0;
        entitiesList.forEach((entity, index) => {
            if (entity.formNameKey === formKey) removeIndex = index;
        });
        const newIndex = removeIndex === 0 ? 0 : removeIndex - 1;

        const listDeepCopy = _.cloneDeep(formState);
        listDeepCopy.forEach((entity) => {
            if (entity?.formNameKey === formKey) {
                entity.parentEntityKeys?.forEach((parentEntity, index) => {
                    if (parentEntity.parentFormName === parentFormName) {
                        parentEntity.isRemoved = true;
                    }
                });
            }
        });

        setEntitiesList(filterEntitiesList(listDeepCopy));
        updateState(listDeepCopy);
        setCurrentIndex(newIndex);
        setEntityBuilderId(uuidv4());
    };

    const updateEntityUniqueValue = ({ field, group }) => {
        const listDeepCopy = _.cloneDeep(formState);

        listDeepCopy.forEach((entity) => {
            if (entity?.formNameKey === formNameKey) {
                entity.parentEntityKeys.forEach((parentEntity) => {
                    if (parentEntity.parentFormName === parentFormName) {
                        if (field) parentEntity[field.uniqueName] = field.default;
                        if (group) parentEntity[group.uniqueVisibilityName] = group.isVisible;
                    }
                });
            }
        });

        setEntitiesList(filterEntitiesList(listDeepCopy));
        updateState(listDeepCopy);
    };

    const setUniqueEntityFieldValue = ({ field, group, formName }) => {
        entitiesList.forEach((entity) => {
            if (entity?.formNameKey === formName ?? formNameKey) {
                entity.parentEntityKeys.forEach((parentEntity) => {
                    if (parentEntity.parentFormName === parentFormName) {
                        if (field) field.default = parentEntity[field.uniqueName];
                        if (group) group.isVisible = parentEntity[group.uniqueVisibilityName];
                    }
                });
            }
        });
    };

    const showSharedEntityDialog = () => {
        handleSharedEntityDialog(dispatch, ACTION, formNameKey, entitiesList, updateStatusCard, year);
    };

    const sectionItems = filteredSections.map((section, index) => {
        if (section.isVisible === false) {
            return (null);
        }

        return (
            <a key={`a-${index}`} className={currentSectionIndex === index ? 'selectedSection' : 'formSection'}
                onClick={!isFormNA ? () => changeSection(index, section) : null} href={`#${section.title}`}>
                {section.title}
            </a>
        );
    }).filter(x => x);

    const sectionTabs = sectionItems.length <= 1 ? null : (
        <div className="formTabSection">
            <div className="formTabsContainer">
                {sectionItems}
            </div>
        </div>
    );

    const currentEntity = entitiesList?.length ? entitiesList[currentIndex] : null;
    const entityIdentifiers = currentEntity?.identifier || [];
    const entitySections = currentEntity?.section || null;

    const compileSectionWithMetadata = (currentEntityMetadata, sections) => {
        if (!currentEntityMetadata) return sections;

        const isNewEntity = _.get(currentEntityMetadata, 'isNewEntity', false);
        const cloneSections = _.cloneDeep(sections);
        cloneSections?.forEach((section) => {
            // Attach entity metadata onto the form sections
            _.set(section, 'isNewEntity', isNewEntity);
        });

        return cloneSections;
    };

    const recompiledSections = compileSectionWithMetadata(currentEntity, sections);
    //for org export entities to render each forms at once
    const recompiledSectionsEntity = (entityIndex) => compileSectionWithMetadata(entitiesList?.length ? entitiesList[entityIndex] : null, sections);
    const entityIndexIdentifiers = (entityIndex) => entitiesList?.length ? entitiesList[entityIndex]?.identifier : [];
    const entityIndexSections = (entityIndex) => entitiesList?.length ? entitiesList[entityIndex]?.section : null;

    const entityScrollTab =
        <EntityScrollTab entities={entitiesList} formName={formName} currentIndex={currentIndex} enableDropdownSelection={iscomingFromVehicleEntity}
            changeEntity={changeEntityIndex} addEntity={addEntity} isLineSection={isLineSection} isFormLocked={isFormDisabled}
            addExistingEntity={addExistingEntity} removeEntityFromList={removeEntityFromList} isParentEntityNA={isParentEntityNA} isExportForm={isExportForm} >
        </EntityScrollTab>;

    const builder = (entity, index) => {
        const entityFormSections = entity ? entityIndexSections(index) : entitySections;
        return !entityFormSections ? null :
            <FormRendererHelper isFormExpandable={isFormExpandable} isFormPresent={isFormPresent}
                sections={entity ? recompiledSectionsEntity(index) : recompiledSections}
                identifiers={entity ? entityIndexIdentifiers(index) : entityIdentifiers}
                formName={entity ? `${formName}-${entity.entityIndex}-${entityCount}` : formNameKey} updateEntity={updateEntity} passFromEntity={true} passedPriorData={true}
                entityScrollTab={entityScrollTab} entityIndex={entity ? entity.entityIndex : currentIndex} updateEntityUniqueValue={updateEntityUniqueValue}
                setUniqueEntityFieldValue={setUniqueEntityFieldValue} showSharedEntityDialog={showSharedEntityDialog}
                parentFormName={parentFormName} isParentEntityNA={isParentEntityNA} isExportForm={isExportForm}
                isScheduleFormSection={isScheduleFormSection} triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic} defaultFormName={formName} barcodeName={barcodeName}
            />
    };

    return (
        !isExportForm ?
            <React.Fragment key={key}>
                {!isFormSection && entityScrollTab}
                {!isMobile && sectionTabs}
                {builder()}
            </React.Fragment> :
            entitiesList.map((entity, index) => {
                return <React.Fragment key={`${key}-${index}`}>
                    {builder(entity, index)}
                </React.Fragment>
            })
    );
}

export default EntityRenderer;