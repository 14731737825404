import React from 'react';
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterButton = ({ setFilterButtonEl, filterHighlight }) => {
    return (
        <GridToolbarFilterButton
            data-testid='custom-toolbar-button'
            ref={setFilterButtonEl}
            componentsProps={{
                button: {
                    startIcon: (
                        <FilterAltIcon className={filterHighlight} data-testid='custom-toolbar-icon' />
                    )
                }
            }}
        />
    );
};

export default FilterButton; 