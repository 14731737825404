import React from 'react';
import { BELOW_INNER_RIGHT_ANCHOR } from '@react-md/utils';
import { DropdownMenu, MenuItem } from 'react-md';
import { MoreVertSVGIcon } from '@react-md/material-icons';

const OptionDropdownMenu = ({ handleExportToCSV, isFilterActive = false, isTileActive = false, orgQuery = '' }) => {

    const isExportDisabled = !isTileActive && !isFilterActive;

    const handleExport = () => {
        if (!isExportDisabled && handleExportToCSV) {
            handleExportToCSV(orgQuery);
        }
    };

    return (
        <DropdownMenu
            id='custom-toolbar-kabob'
            data-testid='custom-toolbar-kabob'
            buttonType="icon"
            buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
            anchor={BELOW_INNER_RIGHT_ANCHOR}
            className="dropdown-menu-container"
        >
            <MenuItem
                className={`export-menu-item ${isExportDisabled ? 'export-disabled' : 'export-enabled'}`}
                onClick={handleExport}
            >
                Export to CSV
            </MenuItem>
        </DropdownMenu>
    );
};

export default OptionDropdownMenu; 