// common modules
import {
	Button,
	Dialog,
	useToggle,
	FontIcon,
} from 'react-md';
import { useState } from 'react';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import { renderTiffImage } from '@utilities/files/convertImageToPdf';

function OrganizerViewUploadDialog(props) {
	const { onFilePreview, downloadFile, buildDocumentPreview, selectState, REDUX } = usePageFramework();
	const documentDownloading = selectState(REDUX.DOCUMENT_DOWNLOADING);
	const { blobFile, className, parentElement, fileName } = props;
	const [visible, show, hide] = useToggle(false);
	const [source, setSource] = useState('');

	const handleDownload = (evt) => {
		evt.preventDefault();
		return downloadFile(blobFile);
	};

	const handlePreview = async () => {
		if (!source) {
			// Load the file for first time open
			if ((blobFile.extension.includes('tiff') || blobFile.extension.includes('tif'))) {
				onFilePreview(blobFile, { responseType: 'arraybuffer' })?.then((arrayBuffer) => {
					let imageBase64 = renderTiffImage(arrayBuffer, blobFile);
					setSource(imageBase64);
					show();
				});
			} else {
				onFilePreview(blobFile)?.then((source) => {
					setSource(source);
					show();
				});
			}
		} else {
			show();
		}
	};

	return (
		<>
			{parentElement ? parentElement :
				<div data-testid='showFileLink' className={className} onClick={handlePreview} first="true" last="true">{fileName}</div>}
			<Dialog
				id={`dialogViewer-${blobFile.upload_id}`}
				visible={visible}
				onRequestClose={() => { }}
				modal={true}
				aria-labelledby="blob-file-viewer"
				className='viewerContainer'
			>
				<div >
					<Button className='fileViewerCLose' id="dialog-close" onClick={hide} first="true" last="true" buttonType='icon'>
						<FontIcon >close</FontIcon>
					</Button>
				</div>
				<h3 className='fileViewerFileName'>{blobFile.name}</h3>
				<a
					className='fileViewerDownload'
					href={'#'}
					target="_blank"
					rel="noreferrer"
					data-testid="fileViewerDownloadId"
					onClick={documentDownloading ? undefined : handleDownload}
					style={{
						pointerEvents: documentDownloading ? 'none' : 'auto',
						opacity: documentDownloading ? 0.5 : 1,
						color: documentDownloading ? 'gray' : '#123A65',
					}}
				>
					Download
				</a>

				{buildDocumentPreview(source, blobFile)}
				{/* { source && <img  src={source} ></img>} */}
			</Dialog>
		</>
	);
}

export default OrganizerViewUploadDialog;
