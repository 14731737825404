'use strict';

import api from '@utilities/claApiV3';

const getFeatureFlags = async () => {
  const featureFlags = [];
  try {
    await api.get('/configurations/1040-feature-flags')
      .then((response) => {
        featureFlags.push(...response.data);
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error(error);
  }
  return featureFlags;
}

export {
  getFeatureFlags
};