import { Button } from 'react-md';
import { Link as ReactRouterLink } from 'react-router-dom';

import { DOCUSIGN_EMBEDDED_SIGNING } from '@utilities/constants/navigation';
import { DASHBOARD_TAX_RETURN } from '@utilities/constants/images';
import { hasPendingTaxReturnStatus } from '@utilities/services/taxReturnService';
import { featureFlagTypes } from '@utilities/constants/featureFlags';
import usePageFramework from '@utilities/hooks/usePageFramework';

import styles from './style.module.scss';

function TaxReturnBannerMobile(props) {
    const { taxReturnStatus, isAdmin, isPractitioner } = props;

    const { selectState, REDUX } = usePageFramework();
    const featureFlags = selectState(REDUX.FEATURE_FLAGS);
    const embeddingUrlFeatureFlag = featureFlags
        ? JSON.parse(JSON.stringify(featureFlags))
            .find(flag => flag.key === featureFlagTypes.EmbeddedSigningUrl)?.value
        : false;
    const isEmbeddingUrlFeatureEnabled = embeddingUrlFeatureFlag === true
        || embeddingUrlFeatureFlag === "true";

    const showTaxReturnBanner = isEmbeddingUrlFeatureEnabled && hasPendingTaxReturnStatus(taxReturnStatus) && !isAdmin && !isPractitioner;

    if (!showTaxReturnBanner)
        return null;

    return hasPendingTaxReturnStatus(taxReturnStatus) && !isAdmin && !isPractitioner
        ? (
            <div className={styles['tax-return-banner']}>
                <div className={styles['tax-return-icon-text']}>
                    <img alt={'TAX RETURN'} src={DASHBOARD_TAX_RETURN} />
                    <div className={styles['tax-return-base-text']}>
                        <div className={styles['header-text']}>Your tax documents are ready to sign.</div>
                        <div className={styles['secondary-text']}>Since your last visit to the 1040, a few documents could use your final signature. Thank you.</div>
                        <ReactRouterLink to={DOCUSIGN_EMBEDDED_SIGNING}>
                            <Button
                                themeType="contained"
                                className={'entryNextButton uploadButton ' + styles['sign-tax-return-btn']}
                            >
                                Sign your tax return
                            </Button>
                        </ReactRouterLink>
                    </div>
                </div>
            </div>
        ) : null;
}

export default TaxReturnBannerMobile;