import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as NAV from '@utilities/constants/navigation';
import { MM_DD_YY_HH_MM_A } from '@utilities/constants/dateFormats';
import moment from 'moment/moment';

// Base API configuration
export const RESOURCE_NAME = 'Scheduled Notifications';
export const RESOURCE_URI = '/schedulednotifications';

// Dropdown options
export const INTERVAL_OPTIONS = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Bi-Weekly', value: 'bi-weekly' }
];

export const notificationType = {
    BEGIN_INDIVIDUAL_TAX_PREPARATION: 'BEGIN_INDIVIDUAL_TAX_PREPARATION',
    BEGIN_INDIVIDUAL_TAX_PREPARATION_EXT: 'BEGIN_INDIVIDUAL_TAX_PREPARATION_EXT',
    CONTINUE_1040_EXCHANGE_SUBMISSION: 'CONTINUE_1040_EXCHANGE_SUBMISSION',
    CONTINUE_1040_EXCHANGE_SUBMISSION_EXT: 'CONTINUE_1040_EXCHANGE_SUBMISSION_EXT',
    COMMUNICATION_OF_1040_EXCHANGE_STATUS_TO_CRL: 'COMMUNICATION_OF_1040_EXCHANGE_STATUS_TO_CRL',
    UPCOMING_INDIVIDUAL_TAX_FILING_DEADLINES_EXT: 'UPCOMING_INDIVIDUAL_TAX_FILING_DEADLINES_EXT',
    UPCOMING_INDIVIDUAL_TAX_FILING_DEADLINES: 'UPCOMING_INDIVIDUAL_TAX_FILING_DEADLINES',
};

export const notificationTypeMap = {
    [notificationType.BEGIN_INDIVIDUAL_TAX_PREPARATION]: 'Begin Individual Tax Preparation',
    [notificationType.COMMUNICATION_OF_1040_EXCHANGE_STATUS_TO_CRL]: 'Communicate 1040 Status to CRL',
    [notificationType.CONTINUE_1040_EXCHANGE_SUBMISSION]: 'Continue Individual Exchange Submission',
    [notificationType.BEGIN_INDIVIDUAL_TAX_PREPARATION_EXT]: '(Ext) Begin Individual Tax Preparation',
    [notificationType.CONTINUE_1040_EXCHANGE_SUBMISSION_EXT]: '(Ext) Continue Individual Exchange Submission',
    [notificationType.UPCOMING_INDIVIDUAL_TAX_FILING_DEADLINES_EXT]: '(Ext) Upcoming Individual Tax Filing Deadlines',
    [notificationType.UPCOMING_INDIVIDUAL_TAX_FILING_DEADLINES]: 'Upcoming Individual Tax Filing Deadlines',
};

const typeOptions = Object.entries(notificationTypeMap)
    .map(([value, label]) => ({ label, value }));

// Navigation configuration
export const NAV_ITEMS = [
    { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
    { to: NAV.MANAGE_NOTIFICATIONS, label: 'Notifications', current: true }
];

// Utility function for building query parameters
export const buildOrganizerQuery = (limit, offset, sorting, filterMethod, filtering) => {
    const filterQuery = filtering?.length ? filtering.map(x => `&filter=${x}`).join('') : '';
    const filterMethodQuery = filterMethod ? `&filterMethod=${filterMethod}` : '';
    const sortQuery = sorting && Object.entries(sorting).length ? Object.entries(sorting).map(([param, query]) => `&${param}=${query}`).join('') : '';
    return `${sortQuery}${filterMethodQuery}${filterQuery}&limit=${limit}&offset=${offset}`;
};

export const getColumnsGrid = ({
    enableCreateDialog,
    setSelected,
    enableEditDialog,
    enableDeleteDialog,
    setFilterButtonEl,
    filterHighlight,
    FilterButtonComponent,
    OptionDropdownMenuComponent,
}) => [
        {
            field: 'filter',
            disableExport: true,
            hideSortIcons: true,
            pinnable: false,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            type: 'text',
            align: 'left',
            editable: true,
            canEdit: true,
            width: 60,
            renderHeader: () => {
                return FilterButtonComponent ? (
                    <FilterButtonComponent
                        setFilterButtonEl={setFilterButtonEl}
                        filterHighlight={filterHighlight}
                    />
                ) : null;
            }
        },
        {
            field: 'title',
            headerName: 'Title',
            type: 'text',
            width: 150,
            editable: false,
            canEdit: true,
            required: true,
            disableColumnMenu: true,

        },
        {
            field: 'description',
            headerName: 'Description',
            type: 'text',
            width: 150,
            editable: false,
            canEdit: true,
            required: true,
            disableColumnMenu: true,
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            type: 'datetimepicker',
            width: 150,
            editable: false,
            canEdit: true,
            required: true,
            renderCell: (params) => {
                const sDate = moment(params.value).format(MM_DD_YY_HH_MM_A);
                return <span>{sDate}</span>;
            },
            disableColumnMenu: true,
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            type: 'datetimepicker',
            width: 150,
            editable: false,
            canEdit: true,
            required: true,
            renderCell: (params) => {
                const eDate = moment(params.value).format(MM_DD_YY_HH_MM_A);
                return <span>{eDate}</span>;
            },
            disableColumnMenu: true,
        },
        {
            field: 'interval',
            headerName: 'Interval',
            type: 'dropDown',
            width: 150,
            editable: false,
            canEdit: true,
            required: true,
            disableColumnMenu: true,
        },
        {
            field: 'type',
            headerName: 'Type',
            type: 'dropDown',
            width: 150,
            editable: false,
            canEdit: true,
            required: true,
            disableColumnMenu: true,
            renderCell: (params) =>
                <span title={notificationTypeMap[params.value]}>
                    {notificationTypeMap[params.value]}
                </span>
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'text',
            width: 150,
            editable: false,
            canEdit: false,
            disableColumnMenu: true,
        },
        {
            field: 'message',
            headerName: 'Message',
            type: 'text',
            width: 150,
            editable: false,
            canEdit: false,
            disableColumnMenu: true,
        },
        {
            field: 'lastSent',
            headerName: 'Last Sent',
            type: 'text',
            width: 150,
            editable: false,
            canEdit: false,
            renderCell: (params) => {
                if (!params.value) return <span></span>;
                const eDate = moment(params.value).format(MM_DD_YY_HH_MM_A);
                return <span>{eDate}</span>;
            },
            disableColumnMenu: true,
        },
        {
            field: 'nextSend',
            headerName: 'Next Send',
            type: 'text',
            width: 150,
            editable: false,
            canEdit: false,
            renderCell: (params) => {
                if (!params.value) return 'N/A';
                const sDate = moment(params.value).format(MM_DD_YY_HH_MM_A);
                return <span>{sDate}</span>;
            },
            disableColumnMenu: true,
        },
        {
            field: 'count',
            headerName: 'Count',
            width: 150,
            editable: false,
            filterable: false,
            canEdit: false,
            disableColumnMenu: true,
        },
        {
            field: 'active',
            headerName: 'Active',
            type: 'boolean',
            width: 150,
            editable: false,
            canEdit: true,
            disableColumnMenu: true,
        },
        {
            field: 'templateId',
            headerName: 'Template Id',
            type: 'text',
            width: 150,
            editable: false,
            canEdit: true,
            required: true,
            disableColumnMenu: true,
        },
        {
            field: 'actions',
            type: 'actions',
            disableExport: true,
            disableColumnMenu: true,
            hideSortIcons: true,
            sortable: false,
            align: 'left',
            editable: false,
            canEdit: false,
            width: 100,
            renderHeader: () => (
                <AddIcon onClick={enableCreateDialog} />
            ),
            renderCell: (params) => (
                <>
                    <EditIcon
                        onClick={() => {
                            setSelected(params.row);
                            enableEditDialog();
                        }}
                    />
                    <DeleteIcon
                        onClick={() => {
                            setSelected(params.row);
                            enableDeleteDialog();
                        }}
                    />
                </>
            )
        },
        {
            field: 'export',
            hideSortIcons: true,
            sortable: false,
            disableColumnMenu: true,
            filterable: false,
            type: 'text',
            align: 'left',
            editable: false,
            disableExport: true,
            pinnable: false,
            canEdit: false,
            width: 35,
            renderHeader: () => {
                return OptionDropdownMenuComponent ? <OptionDropdownMenuComponent /> : null;
            }
        }
    ];

// Helper to prepare columns for dialog
export const getDialogColumns = (columnsGrid) => {
    return columnsGrid
        .filter(col => col.headerName && col.canEdit)
        .map(column => {
            let options = [];
            if (column.type === "dropDown") {
                if (column.field === "type") {
                    options = typeOptions;
                } else if (column.field === "interval") {
                    options = INTERVAL_OPTIONS;
                }
            }
            return {
                key: column.field,
                text: column.headerName,
                type: column.type,
                options,
                create: { show: true, required: column.required },
                edit: { show: true, required: column.required }
            };
        });
}; 