import './footerNotification.css';

const AppFooterNotification = () => {
    let year = new Date().getFullYear();

    return (
        <div className="appFooterNotification" data-testid='app-footer-notification-id'>
            <div className="appFooterNotificationTextSection">
                <a className="appFooterNotificationText" data-testid='app-footer-notification-text-id' href="https://www.claconnect.com/"> {year} CliftonLarsonAllen LLP | CLAConnect.com </a>
                <span className="appFooterNotificationVersion" data-testid='app-footer-notification-version-id'> {`App Version:${process.env.REACT_APP_VERSION}`}</span>
            </div>
        </div>
    );
};

export default AppFooterNotification;
