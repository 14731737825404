// common modules
import React from 'react';

// custom modules
import './KeyStatisticTile.css';
import { Button } from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { featureFlagTypes } from '@utilities/constants/featureFlags';

function KeyStatisticTile(props) {
  const { total, label, completed, isToggled, handleTileClick } = props;

  const { selectState, REDUX } = usePageFramework();
  const featureFlags = selectState(REDUX.FEATURE_FLAGS);
  const enableSuppressedNotificatonsFeature = featureFlags
    ? JSON.parse(JSON.stringify(featureFlags))
      .find(flag => flag.key === featureFlagTypes.ShowSuppressedNotifications)?.value === "true"
    : false;

  const containerStyles = {
    width: '100%',
    backgroundColor: '#d2ecce',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
  };

  const progressFillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: '#2e9514',
    borderRadius: 'inherit',
    textAlign: 'center',
  }

  const percentageTextStyles = {
    textAlign: 'right',
    fontFamily: 'museo-sans',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: .79,
    letterSpacing: 'normal',
    color: '#6e706d',
    marginLeft: '15px'
  }

  const labelStyles = {
    fontSize: '12px',
    marginBottom: '9px'
  }

  const toggledStyle = isToggled ? 'key-statistic-tile-toggled' : '';

  const isSuppressedNotifications = label === 'Suppressed Notifications';
  const hideSuppressedNotificationTiles = !enableSuppressedNotificatonsFeature && isSuppressedNotifications;

  return (
    <>
      {!hideSuppressedNotificationTiles && <Button className={`key-statistic-tile ${toggledStyle}`} onClick={handleTileClick}>
        <div className="key-statistic-tile-content">
          <div className="key-statistic-tile-count">
            {total}
          </div>
          <span className="key-statistic-tile-label" style={labelStyles}>
            {label}
          </span>
          {completed !== null && <div className="key-statistic-tile-progress-bar">
            <div style={containerStyles}>
              <div style={progressFillerStyles} />
            </div>
            <span style={percentageTextStyles}>{`${completed}%`}</span>
          </div>}
        </div>
      </Button>}
    </>
  )
}

export default KeyStatisticTile;