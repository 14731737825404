import { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogHeader,
	DialogTitle,
	DialogContent,
	DialogFooter,
} from 'react-md';
import './entryModal.scss';
import { CloseSVGIcon } from '@react-md/material-icons';
import { Stepper, Step } from 'react-form-stepper';
import _ from 'lodash';
import EntryWelcomeIntro from '../welcomeIntro/index';
import EntryIconCard from '../entryIconCard/index';
import EntryModalPrevNextIconButtons from '../entryPrevNextIconButtons/index';
import AgreementAcceptance from '../agreementAcceptance/index';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import api from '@utilities/claApi';
import * as IMG from '@utilities/constants/images';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import lastUserActivityDate from '@utilities/helpers/lastUserActivityDate';
import { deriveOrganizerActivity } from '@utilities/organizerData';
import useAdaptive from '@utilities/hooks/useAdaptive';
import GettingStartedModal from '../gettingStarted';
import MobileLimitedExperience from '@components/entryExperience/mobileLimitedExperience';

import { loadOrganizerDetails } from '@utilities/helpers/loadOrganizerDetails';
import getYear from '@utilities/helpers/getYear';
import getDashboard from '@utilities/constants/dashboards';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';

function EntryExperienceModal() {
	const {
		history,
		NAV,
		REDUX,
		selectState,
		dispatch,
		ACTION,
		organizerId,
		validateCurrentCard,
		setEntityFormState,
		location,
		updateUploadList
	} = usePageFramework();
	const gettingStarted = new URLSearchParams(location.state).get('gettingStarted');
	const agreementAcceptance = new URLSearchParams(location.state).get('agreementAcceptance');
	const year = getYear(selectState);
	const { dashboard } = getDashboard(year.current);
	const priorYearDetails = selectState(REDUX.PRIOR_YEAR_DETAILS);
	const priorYearData = selectState(REDUX.PRIOR_YEAR_DATA);
	const lastUserActivity = lastUserActivityDate(selectState);
	const organizer = selectState(REDUX.SELECTED_ORGANIZER);

	// Temporarily set  visible to false on app load
	const stepsList = [{ type: 'Income', title: 'Please tell us about your income' }, { type: 'Expenses', title: 'Please tell us about your expenses' }, { type: 'Services', title: 'Please see what additional services interest you' },];
	const [currentStep, setCurrentStep] = useState(0);
	const [isAgreementAcceptance, setIsAgreementAcceptance] = useState(agreementAcceptance || false);
	const [isGettingStarted, setIsGettingStarted] = useState(gettingStarted || false);
	const [isOpen, setIsOpen] = useState(!!location?.hash?.includes('#welcome'));
	const completedEntryStep = selectState(REDUX.COMPLETED_ENTRY_STEP);
	const [isShowLimitedExp, setIsShowLimitedExp] = useState(false);
	const [categories, setCategories] = useState([]);
	const [isOrganizerLoaded, setIsOrganizerLoaded] = useState(false);

	const { isLaptopOrDesktop, isDesktopDevice, isMobileDevice } = useAdaptive();
	const taxpayerName = selectState(REDUX.TAXPAYER_NAME);
	const taxpayerSpouseName = selectState(REDUX.TAXPAYER_SPOUSE_NAME);
	const entryModalOpen = selectState(REDUX.ENTRY_MODAL_OPEN);
	const activeReturn = selectState(REDUX.ACTIVE_RETURN);
	const hasMultipleReturns = selectState(REDUX.HAS_MULTIPLE_RETURNS);
	const isClient = selectState(REDUX.IS_CLIENT);

	useEffect(async () => {
		if (isOrganizerLoaded || !organizerId || !activeReturn?.clientNumber) return;
		if (dashboard) {
			setCategories(dashboard);
		}
		try {
			const { isPriorYearDataError } = await loadOrganizerDetails(organizerId, dispatch, ACTION, api, updateUploadList, setEntityFormState, activeReturn?.clientNumber, categories, isClient, year?.current);
			if (isPriorYearDataError) {
				history.push(NAV.REQUEST_ERROR)
			}
			else setIsOrganizerLoaded(true);
		} catch (error) {
			console.log('Error loading organizer:', error);
		}
	}, [activeReturn, organizerId, activeReturn?.clientNumber]);

	useEffect(() => {
		if (priorYearData?.length) {
			const updatedDash = setRequiredForms(priorYearData || [], _.cloneDeep(dashboard));
			setCategories(updatedDash);
		}
	}, [priorYearData]);

	const onCloseModal = () => {
		dispatch(ACTION.setEntryModalOpen(false));
		history.push(`${NAV.DASHBOARD}/${organizerId}`);
	};

	const checkEntryExperience = async (currentId) => {
		let isPriorYearDataError = false;
		if (currentId) {
			dispatch(ACTION.setFutureYear(false));
		}
		if (organizer?.entryExperience?.completedStep) {
			dispatch(ACTION.setEntryModalOpen(false));
			try {
				dispatch(ACTION.setProgressText(`Loading Client Data for\n${taxpayerName}${taxpayerSpouseName ? ` \nand ${taxpayerSpouseName}` : ''}`));

				dispatch(ACTION.setProgressVisible(true));

				// Load the organizer details

				const result = await loadOrganizerDetails(organizerId, dispatch, ACTION, api, updateUploadList, setEntityFormState, activeReturn?.clientNumber, categories, isClient, year?.current);

				isPriorYearDataError = result.isPriorYearDataError;
			} catch (error) {
				console.error('Error loading organizer data:', error.message);
				console.error('Error Error loading organizer data:', error.stack);
				dispatch(ACTION.setShowCustomDialog(true));
				dispatch(ACTION.setCustomDialogTitle(error.message));
				dispatch(ACTION.setCustomDialogMsg(error.message));
			} finally {
				dispatch(ACTION.setProgressVisible(false));
				if (isPriorYearDataError) {
					history.push(NAV.REQUEST_ERROR)
				} else {
					history.push(`${NAV.DASHBOARD}/${organizerId}`);
				}
			};

		} else if (!organizer?.isTermsAgreed) {
			setIsAgreementAcceptance(true);
			setIsGettingStarted(false);
			setCurrentStep(0);
		}
		else if (organizer.isTermsAgreed && !completedEntryStep) {
			setIsAgreementAcceptance(false);
			showGettingStarted();
		}
	};

	const showGettingStarted = () => {
		if (completedEntryStep) {
			setIsOpen(false);
			dispatch(ACTION.setHasSkipEntryStep(true));
			history.push(`${NAV.DASHBOARD}/${organizerId}`);
		} else {
			setIsAgreementAcceptance(false);
			setIsGettingStarted(true);
			setCurrentStep(0);
		}
	};

	const showLimitedExp = () => {
		saveEntryExperience(true);
	};

	const navigateToPreviousPage = () => {
		setIsAgreementAcceptance(false);
		setIsGettingStarted(true);
		setCurrentStep(0);
	};

	const buildStepStyle = (index) => {
		let stepStyle = <div data-testid={'build-step-dot-' + index} className={index < stepIndex ? 'entryExperienceCompletedCheck' : index === stepIndex ? 'entryExperienceActiveDot' : index > stepIndex ? 'entryExperienceInactiveDot' : 'entryExperienceInactiveDot'}></div>;
		return (
			//eslint-disable-next-line
			<a data-testid={'build-step-id-' + index} onClick={() => setCurrentStep(index + 1)}>{stepStyle}</a>
		);
	};

	const buildStepView = () => {
		const cardsByType = [];

		for (let i = 0; i < categories.length; i++) {
			const cardGroup = categories[i].cards.filter(card =>
				card.entryType === stepsList[stepIndex]?.type
			);
			if (cardGroup.length > 0) {
				cardsByType.push(cardGroup);
			}
		}

		const foreignAssetsCard = categories[0]?.cards?.find(card => card.formName === 'foreignAssets');
		return (
			<div className="entryIconContainer">
				{
					cardsByType[0]?.map((card, index) => (
						<EntryIconCard key={`entry-card-${card.key}-${index}`} card={card} />
					))
				}
				{cardsByType[0]?.[0].entryType === 'Income' ? <EntryIconCard key={`entry-card-${foreignAssetsCard.key}`} card={foreignAssetsCard} /> : null}
			</div>

		);
	};

	const saveEntryExperience = async (isMobileSave) => {
		let isPriorYearDataError = false;
		try {
			dispatch(ACTION.setProgressText(`Loading Client Data for\n${taxpayerName}${taxpayerSpouseName ? ` \nand ${taxpayerSpouseName}` : ''}`));

			dispatch(ACTION.setProgressVisible(true));

			// Load the organizer details

			const result = await loadOrganizerDetails(organizerId, dispatch, ACTION, api, updateUploadList, setEntityFormState, activeReturn?.clientNumber, categories, isClient, year?.current);

			isPriorYearDataError = result.isPriorYearDataError;
			const completedStep = isMobileSave ? 1 : stepIndex + 1;
			const json = {
				entryExperience: {
					completedStep: completedStep,
				},
				lastUserActivityOn: lastUserActivity,
				status: 'In Progress'
			};

			const derivedOrganizerData = deriveOrganizerActivity(json, new Date());
			const payload = Object.assign({}, json, derivedOrganizerData);

			await api.put(`/organizers/${organizerId}`, payload);
			dispatch(ACTION.setCompletedEntryStep(completedStep));
			dispatch(ACTION.setHasSkipEntryStep(true));

		} catch (error) {
			console.error('Error during saveEntryExperience:', error.message);
			console.error('Error during saveEntryExperience:', error.stack);
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setCustomDialogTitle('An unexpected error occurred.'));
			dispatch(ACTION.setCustomDialogMsg('Please try again later.'));
		} finally {
			if (isPriorYearDataError) {
				dispatch(ACTION.setProgressVisible(false));
				history.push(NAV.REQUEST_ERROR)
			} else {
				const limit = 50;
				const {
					data: { results: organizers },
				} = await api.get(
					`/organizers?filter=year eq ${currentYear}&limit=${limit}`
				).catch((err) => {
				history.push(`${NAV.ACCESS_TO_CLIENT_ERROR}`);
			});
			
				if (organizers.length === 0) {
				history.push(`${NAV.ACCESS_TO_CLIENT_ERROR}`);
			} else {
					dispatch(ACTION.setClientOrganizers(organizers));
					dispatch(ACTION.setProgressVisible(false));
					if (isMobileSave) {
						setIsAgreementAcceptance(false);
						setIsShowLimitedExp(true);
					} else onCloseModal();
			}
			}
		};
	};

	const showProgressDialog = () => {
		dispatch(ACTION.setProgressText('Loading Client Data...'));
		dispatch(ACTION.setProgressVisible(true));
	};

	const hideProgressDialog = () => {
		dispatch(ACTION.setProgressText(''));
		dispatch(ACTION.setProgressVisible(false));
	};

	const showPriorTaxInformation = async () => {
		showProgressDialog();
		dispatch(ACTION.setFutureYear(false));
		dispatch(ACTION.setUploadList([]));
		api.get(`/organizers/${priorYearDetails.priorOrganizerId}`, {}).then(async (result) => {
			let hasVehicleData = false;
			if (!result?.data) throw new Error('No data available');
			const { dashboard } = await api.get(`organizers/${result?.data?.id}/dashboard`).then(response => response.data);

			const { forms, client, year, status, notes } = result.data;
			const priorData = await api.get(`/organizers/${priorYearDetails.priorOrganizerId}/prior`).then((response) => {
				dispatch(ACTION.setPriorYearData(response.data.data.taxData.priorYear));
				return response.data.data.taxData;
			}).catch((err) => {
				console.error(err);
			});
			// Set dashboard data into redux
			const newDash = getDashboard();
			dispatch(ACTION.setDashboard(dashboard ? dashboard : newDash));

			// Set form data into redux
			const formKeys = [];
			forms?.forEach((form) => {
				if (!formKeys.includes(form.key)) {
					dispatch(ACTION.setForm(form.key, form.data));
					hasVehicleData = hasVehicleData || _.startsWith(form.key, REDUX.VEHICLE);
					formKeys.push(form.key);
				}
			});

			// Load vehicles if the client does not have any vehicle data
			if (priorData && priorData.priorYear && !hasVehicleData) {
				const vehicleFormData = loadVehicleData(priorData.priorYear);
				if (vehicleFormData && Object.keys(vehicleFormData).length) {
					Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
						setEntityFormState(vehicleKey, vehicleForm, organizerId);
					});
				}
			}

			// Set organizer metadata
			const clientInfo = {
				displayName: client.name,
				clientNumber: client?.number ?? '',
				currentYear: year,
				formStatus: status
			};

			await api.get(`organizers/${priorYearDetails.priorOrganizerId}/notes`).then((response) => {
				dispatch(ACTION.setFormNotes(response.data.results));
			});

			dispatch(ACTION.setOrganizerId(priorYearDetails.priorOrganizerId));
			dispatch(ACTION.setActiveReturn(clientInfo));
			dispatch(ACTION.setLockForms(true));
			dispatch(ACTION.setYear(year));
		}).catch((err) => {
			console.error(err);
		}).finally(() => {
			hideProgressDialog();
			onCloseModal();
		});
	};

	const showUploadDocument = async () => {
		validateCurrentCard();
		dispatch(ACTION.setCurrentCardKey(null));
		let futureYear = currentYear + 1;
		dispatch(ACTION.setYear(futureYear));
		dispatch(ACTION.setFutureYear(true));
		history.push(`${NAV.DOCUMENT_MANAGER}`);
	};

	const stepIndex = currentStep - 1;

	return (
		<>
			<Dialog
				id="simple-dialog"
				visible={entryModalOpen}
				onRequestClose={() => { }}
				aria-labelledby="entry-experience-dialog-title"
				className={isLaptopOrDesktop && isDesktopDevice ? "entry-dialog-container" : "entry-dialog-mobile-container"}
				data-testid="simple-dialog-container"
				style={isShowLimitedExp ? { height: '80%' } : null}
			>

				{
					!isAgreementAcceptance && !isGettingStarted && isShowLimitedExp ?
						<MobileLimitedExperience onCloseModal={onCloseModal} /> :
						!isAgreementAcceptance && !isGettingStarted ?
							<EntryWelcomeIntro data-testid="entry-welcomeIntro" moveAgreement={checkEntryExperience} showPriorTaxInformation={showPriorTaxInformation} showUploadDocument={showUploadDocument} /> :
							isAgreementAcceptance && !isGettingStarted && !currentStep ?
								<AgreementAcceptance setCurrentStep={setCurrentStep} showLimitedExp={showLimitedExp} mobileSaveEntry={saveEntryExperience} showGettingStarted={showGettingStarted} setIsAgreementAcceptance={setIsAgreementAcceptance} setIsGettingStarted={setIsGettingStarted} /> :
								!isAgreementAcceptance && isGettingStarted && !currentStep ?
									<GettingStartedModal setCurrentStep={setCurrentStep} mobileSaveEntry={saveEntryExperience} /> :
									<>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}>
											<div style={{
												display: 'flex',
												alignItems: 'center',
											}}>
												<img src={IMG.CLA_NAVY_LOGO} alt="CLA navy colored logo" className="entryHeaderSilIcon" style={{
													marginTop: isMobileDevice ? null : '0px', width: '39px', height: '40.16px'
												}}></img>

												<DialogHeader style={{
													paddingLeft: '20px',
												}}>
													<DialogTitle id="entry-experience-dialog-title" className="entry-experience-title"
														style={{
															lineHeight: '25px', fontSize: '25px', color: '#303E59'
														}}
													>
														Welcome to the <span className="" style={{ lineHeight: '25px', color: '#303E59' }}> 1040 Exchange{hasMultipleReturns ? ',' : ''}</span>
														<p style={{
															fontSize: '25px',
															fontWeight: '600',
															color: '#303E59',
															margin: '0px',
															lineHeight: '25px',
															marginTop: '4px',
														}}>{taxpayerName} {taxpayerSpouseName ? `and ${taxpayerSpouseName}` : null}</p>
													</DialogTitle>
												</DialogHeader>
											</div>
											<Button
												id="icon-button-close"
												data-testid="icon-button-close"
												buttonType="icon"
												theme="clear"
												aria-label="close"
												className="entryExperienceModalCloseBtn"
												onClick={() => saveEntryExperience()}
												style={{
													top: '10px',
													right: '15px'
												}}
											>
												<CloseSVGIcon className="closeIcon" />
											</Button>
										</div>
										<DialogContent className="entry-modal-content entry-modal-content-mobile">
											<div style={{ height: '560px' }}>
												<div className="entry-step-text-container">
													<div className="entry-please-tell-us-about">{stepsList[stepIndex]?.title}</div>
													<div className="entry-step-description">To get things started, we highlighted the items we worked on with you last year.</div>
													<div className="entry-step-description">Please select or deselect anything that has changed.</div>
												</div>

												<Stepper
													activeStep={stepIndex}
													connectorStateColors={true}
													styleConfig={{ inactiveBgColor: '#FFFFFF', activeBgColor: '#7DD2D3', completedBgColor: '#7DD2D3', size: '24px', circleFontSize: '12px' }}
													connectorStyleConfig={{ disabledColor: '#d2d2d2', activeColor: '#7dd2d3', completedColor: '#7dd2d3', size: 3, }}
												>
													{stepsList.map((step, index) => (
														<Step key={`entry-modal-step${index}`} label={step.type}> {buildStepStyle(index)}</Step>
													))}
												</Stepper>

												{buildStepView()}

												<DialogFooter className='entryExperienceFooter fixed-footer'>
													<EntryModalPrevNextIconButtons setCurrentStep={setCurrentStep} stepIndex={stepIndex} saveStep={saveEntryExperience} navigateToPreviousPage={navigateToPreviousPage} />
												</DialogFooter>
											</div>
										</DialogContent>
										<DialogFooter className='entryExperienceFooter fixed-footer'></DialogFooter>
									</>
				}
			</Dialog>
		</>
	);
}

export default EntryExperienceModal;