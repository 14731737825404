import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { parseToUSDate } from '@components/organizerExport/organizerRenderer/components/exportRenderer/fields/utils/formatDate';

const NotesIndex = ({ clientName, forms }) => {
  const { REDUX, selectState } = usePageFramework();
  const notesList = selectState(REDUX.FORM_NOTES) || [];
  const [formNotes, setFormNotes] = useState([]);

  useEffect(() => {
    if (clientName && !formNotes.length) {
      handleNotes();
    }
    //eslint-disable-next-line
  }, [notesList, clientName]);

  const handleNotes = () => {
    let notes = [];
    if (notesList.length) {
      notesList.forEach(note => {
        let form = forms.find(item => item.formName === note.key);
        if (note.key === 'dashboard') {
          notes.unshift({
            title: `Dashboard Note`,
            value: note.value,
            date: parseToUSDate(note.createdOn),
            updatedBy: note.updatedBy,
            clientName
          });
        } else if (form) {
          notes.push({
            title: `${form.title}`,
            value: note.value,
            date: parseToUSDate(note.createdOn),
            updatedBy: note.updatedBy,
            clientName
          });
        }
      });
    }

    setFormNotes(notes);
  }

  return (
    <>
      <div className="notesHeaderTitle">
        <h2>Notes</h2>
      </div>
      <div className="tableHeader">
        <h4>Note</h4>
        <h4>Date</h4>
      </div>
      <hr />
      <div className='notesGrid'>
        {formNotes.map(note => (
          <div key={note.title}>
            <div>{note.title} - {note.value}</div>
            <div>{note.date}, {note.updatedBy}</div>
          </div>
        ))}
      </div>
    </>
  )
}

export default NotesIndex;