
import React from 'react';
import { Button } from 'react-md';
import { Link as ReactRouterLink } from 'react-router-dom';

import { DASHBOARD_TAX_RETURN } from '@utilities/constants/images';
import { hasPendingTaxReturnStatus } from '@utilities/services/taxReturnService';
import { DOCUSIGN_EMBEDDED_SIGNING } from '@utilities/constants/navigation';
import { featureFlagTypes } from '@utilities/constants/featureFlags';
import usePageFramework from '@utilities/hooks/usePageFramework';

import './style.scss';

function TaxReturnBanner(props) {
    const { taxReturnStatus, isAdmin, isPractitioner } = props;

    const { selectState, REDUX } = usePageFramework();
    const featureFlags = selectState(REDUX.FEATURE_FLAGS);
    const embeddingUrlFeatureFlag = featureFlags
        ? JSON.parse(JSON.stringify(featureFlags))
            .find(flag => flag.key === featureFlagTypes.EmbeddedSigningUrl)?.value
        : false;
    const isEmbeddingUrlFeatureEnabled = embeddingUrlFeatureFlag === true
        || embeddingUrlFeatureFlag === "true";

    const showTaxReturnBanner = isEmbeddingUrlFeatureEnabled && hasPendingTaxReturnStatus(taxReturnStatus) && !isAdmin && !isPractitioner;

    if (!showTaxReturnBanner)
        return null;

    return (
        <div className={'tax-return-banner'}>
            <div className={'tax-return-icon-text'}>
                <img alt={'TAX RETURN'} src={DASHBOARD_TAX_RETURN} />
                <div className={'tax-return-base-text'}>
                    <div className={'header-text'}>Your tax documents are ready to sign.</div>
                    <div>Since your last visit to the 1040, a few documents could use your final signature. Thank you.</div>
                </div>
            </div>
            <ReactRouterLink to={DOCUSIGN_EMBEDDED_SIGNING} onClick={() => window.scrollTo(0, 0)}>
                <Button
                    themeType="contained"
                    className={'entryNextButton uploadButton sign-tax-return-btn'}
                >
                    Sign your tax return
                </Button>
            </ReactRouterLink>
        </div>
    );
}

export default TaxReturnBanner;