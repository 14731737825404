import _ from 'lodash';

import getVehicleFormSection from '@views/income/newVehicleEntity/vehicleFormSection';
import getProviderFormSection from '@views/deductions/newProviderEntity/providerFormSection';
import * as REDUX from '@utilities/constants/redux.js';
import { findEntityNameFields, findFromPYData, getFieldObject } from '@utilities/axcessTaxPull.js';
import loadPriorEntityData from '@utilities/populatePriorData/parsePriorEntityData.js';

const generateParentFormName = (sourceKey, sourceEntityIndex) => {
    if (!sourceKey || isNaN(parseInt(sourceEntityIndex))) return null;

    // TODO: Update first or second index number depending on the parent source key.
    if (sourceKey === 'passthroughIncome') {
        return `${sourceKey}-0-${parseInt(sourceEntityIndex)}`;
    }
    
    return `${sourceKey}-${parseInt(sourceEntityIndex)}-0`;
};

const generateParentFormAssociation = (priorYearVehicle, sourceKey, sourceEntityIndex, ) => {
    const parentFormName = generateParentFormName(sourceKey, sourceEntityIndex);
    const parentFormAssociation = parentFormName ? { parentFormName } : {};
    
    // Load unique values into parent association object
    priorYearVehicle?.[0]?.data?.forEach((dataSet) => {
        dataSet?.fields?.forEach((x) => {
            if (x?.uniqueName && x?.value) {
                parentFormAssociation[x.uniqueName] = x.value
            }
        });
    });

    return parentFormAssociation;
};

const buildEntityObject = (priorYearEntity, entityNumber, year) => {
    const { source, sourceKey, sourceIndex, vehicleNumber, entityIndex } = priorYearEntity[0] || {};

    let prior, current, next = 0;

    if (year) {
        prior = year - 1;
        current = year;
        next = year + 1;
    }

    const yearObj = Object.freeze({
        prior,
        current,
        next,
    });

    const vehicleFormSectionProps = {
        isFormExpandable: sourceKey === 'passthroughIncome' ? true : undefined,
        isLineSection: sourceKey === 'passthroughIncome' ? true : undefined,
        year: yearObj,
    };

    const providerFormSectionProps = {
        isFormExpandable: sourceKey === 'passthroughIncome' ? true : undefined,
        isLineSection: sourceKey === 'passthroughIncome' ? true : undefined,
        year: yearObj
    };

    const vehicleFormSections = _.cloneDeep(getVehicleFormSection(vehicleFormSectionProps));
    const providerFormSections = _.cloneDeep(getProviderFormSection(providerFormSectionProps));
    var entityNameFields = '';
    var entitiyNames = '';

    // Scan and load entity name fields
    if (sourceKey === 'provider')
    {
        entityNameFields = findEntityNameFields(providerFormSections); 
        entitiyNames = entityNameFields
        .map((fieldLocation) => getFieldObject(providerFormSections, fieldLocation))
        .map(field => findFromPYData(priorYearEntity, field))
        .filter(x => x);
    }
    else
    {
        entityNameFields = findEntityNameFields(vehicleFormSections);    
        entitiyNames = entityNameFields
        .map((fieldLocation) => getFieldObject(vehicleFormSections, fieldLocation))
        .map(field => findFromPYData(priorYearEntity, field))
        .filter(x => x);    
    }

    const parentFormAssociation = generateParentFormAssociation(priorYearEntity, sourceKey, sourceIndex);

    const entityData = {
        entityIndex: entityNumber,
        name: entitiyNames?.[0] || `${source} ${vehicleNumber}`,
        identifer: priorYearEntity,
        section: vehicleFormSections,
        formNameKey: `${REDUX.VEHICLE}-${_.toSafeInteger(vehicleNumber)}-0`,
        parentEntityKeys: [parentFormAssociation],
    };

    return entityData;
};

const buildAndPopulateEntityForm = (identifiers, priorYearData, year) => {
    const initialEntitiesList = [];

    const priorYearEntities = loadPriorEntityData(identifiers, null, null, priorYearData, null, { groupByEntityId: true });

    priorYearEntities.forEach((priorYearEntity) => {
        const entity = buildEntityObject(priorYearEntity, initialEntitiesList.length, year);
        initialEntitiesList.push(entity);
    });

    return initialEntitiesList;
};

export default buildAndPopulateEntityForm;

export {
    buildAndPopulateEntityForm,
    // Exported for testing
    generateParentFormName,
    generateParentFormAssociation,
    buildEntityObject,
};